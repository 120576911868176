import { apiFactory } from '@/utils/constant'

const attributeApi = {
  list: '/orderService/exportFieldManagement/list',
  update: '/orderService/exportFieldManagement/update'
}

for (const key in attributeApi) {
  attributeApi[key] = apiFactory(attributeApi[key])
}

export default attributeApi
