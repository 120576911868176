<template>
  <dialogForm
    :option="option"
    width="500"
    title="变量启用"
    confirmText="确定启用"
    v-bind="$attrs"
    v-on="$listeners"
    v-model="form"
    @opened="onOpen"
    @submit="onSubmit"
  >
    <template #reference>
      <color-text-btn style="font-size: 12px">启用</color-text-btn>
    </template>
  </dialogForm>
</template>

<script>
import attributeApi from '@/api/productTemplate/attributeManage'
import { requireFun } from '@/utils'

export default {
  props: {
    sup_this: Object,
    data: Object
  },

  data() {
    return {
      ENABLE: 1,
      form: {},
      option: {
        menuAlign: 'left',
        searchSize: 'small',
        labelPosition: 'left',
        column: [
          {
            label: '支持类型',
            prop: 'templateUsageType',
            span: 22,
            type: 'checkbox',
            labelWidth: '90',
            dicData: [
              {
                label: '订单导出',
                value: 1
              },
              {
                label: '产品导出',
                value: 2
              }
            ],
            rules: [
              {
                required: true,
                message: requireFun('支持类型')
              }
            ]
          }
        ]
      }
    }
  },

  methods: {
    onOpen() {
      this.$set(this.form, 'templateUsageType', this.data.templateUsageType)
    },

    async onSubmit(data, done) {
      const { id } = this.data
      const { templateUsageType } = this.form
      const res = await awaitResolve(
        attributeApi.update({
          id,
          isEnable: ENABLE,
          templateUsageType
        })
      )
      if (!res) return
      this.sup_this.init()
      this.$message.success('操作成功')
    }
  }
}
</script>

<style lang="scss" scoped></style>
